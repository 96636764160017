<template>
  <v-dialog v-model="bDialogConfirmGlobal" :width="screenWidth" persistent>
    <div class="content-card-dialog">
      <v-btn class="content-card-button-close" @click="closeDialog" icon>
        <v-icon color="#000" size="16px"> mdi-close </v-icon>
      </v-btn>
      <p class="content-card-txt-title poppins">
        {{ oItem.sTitleDialog }}
      </p>
      <p
        class="content-card-txt-description poppins mt-5 mb-0"
        v-html="oItem.sQuestion"
      />
      <div v-if="oItem.sDoubleConfirmation" class="content-checkbox-confirm">
        <v-checkbox v-model="bConfirm" color="#2759a2" :hide-details="true">
          <template slot="label">
            <span class="content-card-txt-description poppins">
              {{ oItem.sConfirmation }}
            </span>
          </template>
        </v-checkbox>
      </div>
      <div class="content-buttons">
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col cols="12" sm="5">
              <div
                class="display-flex align-items-center justify-content-flex-start"
              >
                <v-btn
                  @click="closeDialog"
                  class="button-secondary poppins"
                  elevation="0"
                  width="100%"
                >
                  Cancelar
                </v-btn>
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="12" sm="5">
              <div
                class="display-flex align-items-center justify-content-flex-end"
              >
                <v-btn
                  @click="confirmPre"
                  :loading="bLoading"
                  class="button-primary poppins"
                  :disabled="oItem.sDoubleConfirmation ? !bConfirm : false"
                  elevation="0"
                  width="100%"
                >
                  {{ oItem.sNameBtnAction }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogConfirmGlobal",
  props: {
    bDialogConfirmGlobal: Boolean,
    oItem: Object,
  },
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
      bConfirm: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 600) {
        this.screenWidth = 520 + "px";
      } else {
        this.screenWidth = 100 + "%";
      }
    },
    closeDialog: function () {
      this.$emit("setDialogConfirmGlobal");
      this.bConfirm = false;
    },
    confirmPre() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            const config = {
                headers: {
                  Authorization: `Bearer ${this.$store.state.sToken}`,
                },
              },
              payload = {};
            DB.patch(
              `${URI}/advance-invoices/${this.$route.params.id}`,
              payload,
              config
            )
              .then((response) => {
                this.$store.commit("refresher", true);
                this.mixSuccess(response.data.message);
              })
              .catch((error) => {
                this.bLoading = false;
                this.mixError(
                  error.response.data.message,
                  error.response.status
                );
              });
          } else {
            this.bLoading = false;
            this.$store.commit("refresher", true);
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
          }
        })
        .catch((error) => {
          this.bLoading = false;
          this.$store.commit("refresher", true);
          this.mixError(error.message, error.code);
        });
      // const config = {
      //     headers: {
      //         Authorization: `Bearer ${this.$store.state.sToken}`,
      //     },
      // },
      //     payload = {
      //     };

      // DB.patch(
      //     `${URI}/advance-invoices/${this.$route.params.id}`,
      //     payload,
      //     config
      // )
      //     .then((response) => {

      //     })
      //     .catch((error) => {
      //         this.mixError(error.response.data.message, error.response.status);
      //         this.bLoading = false;
      //     });
    },
  },
  watch: {
    bDialogConfirmGlobal() {},
  },
};
</script>

<style scoped>
.content-inputs {
  margin-top: 50px;
}

.content-buttons {
  margin-top: 25px;
}

.content-checkbox-confirm {
  display: flex;
  justify-content: center;
  margin: 0px 50px 0px 50px;
}
</style>
